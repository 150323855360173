import { useContext } from "react";
import { UserContext } from "contexts/UserContext";
import { Field, Form } from "react-final-form";
import API from "rest/api";
import classNames from "classnames";
import { FormQuestion } from "components/FormQuestion";

const formUrl =
  "https://docs.google.com/forms/u/0/d/e/1FAIpQLSdxcmwQQwnY6eHgnNhSOXpvfQ0gQPq6Mhhl3nZ6nhW4Trb8mg/formResponse";

const Feedback = () => {
  const { user } = useContext(UserContext);

  // get user id from search params
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const userId = user?.id ?? params.get("userid") ?? "undefined";

  const { mutate, isSuccess } = API.forms.useCreateForm();

  const onSubmit = ({ feedback }: { feedback: string }) => {
    console.log({ feedback });
    const formData: [string, string | number][] = [];
    // entry.583911288 sessions
    // entry.1203193306 feedback
    // entry.1010158166 userid

    formData.push(["entry.1033884994", feedback ?? "undefined"]);
    formData.push(["entry.2144510565", userId]);

    mutate({ url: formUrl, form: formData });
  };

  return (
    <>
      <div className="relative flex flex-col m-4 p-8 bg-slate-50 rounded-lg max-w-2xl sm:mx-auto shadow-xl">
        <h1 className="text-center">Give us feedback</h1>

        {isSuccess ? (
          <>
            <p>Thanks for your submission!</p>
          </>
        ) : (
          <>
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                  <input type="hidden" name="form-name" value="ladies-night" />
                  <div className="flex flex-col space-y-6">
                    <FormQuestion
                      label="Do you have ideas or suggestions for the club? We'd love to hear
              from you!"
                    >
                      <Field name="feedback">
                        {({ input }) => (
                          <textarea
                            {...input}
                            className="border-2 h-40 p-4"
                          ></textarea>
                        )}
                      </Field>
                    </FormQuestion>
                    <button
                      disabled={!values.feedback}
                      type="submit"
                      className={classNames(
                        "border-2 rounded-lg self-center px-4 py-2 bg-slate-700 text-white ",
                        { "bg-gray-500 cursor-not-allowed": !values.feedback }
                      )}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              )}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Feedback;
