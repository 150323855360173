import { PropsWithChildren } from "react";

export const FormQuestion: React.FC<PropsWithChildren<{ label: string }>> = ({
  children,
  label,
}) => (
  <div className="flex flex-col space-y-2">
    <label className="text-gray-500 italic">{label}</label>
    {children}
  </div>
);
