import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "components/Header";
import CTAMenu from "components/CTAMenu";
import style from "./style.module.scss";

import { Actions, Categories } from "analytics/events";
import { Toaster } from "react-hot-toast";
import PendingChallenges from "components/PendingChallenges";
import { useAnalyticsConext } from "contexts/AnalyticsContext";
import { captureSource } from "utils/source";

interface IBase {
  children: React.ReactNode;
}
const Base = ({ children }: IBase) => {
  const { setEvent } = useAnalyticsConext();

  useEffect(() => {
    captureSource();
  }, []);

  const handleBookCourt = () => {
    setEvent(Actions.book_court, Categories.button);
  };

  return (
    <div className={style.base}>
      <Header />
      <CTAMenu>
        <h2>PLAY AT MANLY</h2>
        <Link to="/social">SOCIAL NIGHTS</Link>
        <Link to="/faq">CONTACT US</Link>
        <Link to="/social" onClick={handleBookCourt}>
          BOOK A COURT
        </Link>
      </CTAMenu>
      <section className={style.background}>
        <section className={style.area}>{children}</section>
      </section>
      {/* <Link
        //href="https://northmanlysquashwrc.simplybook.me/v2/#book"
        to="/social"
        className={style.book}
        onClick={handleBookCourt}
      >
        BOOK A COURT
      </Link> */}
      <PendingChallenges />
      <Toaster position="bottom-center" />
    </div>
  );
};

export default Base;
