import Information from "components/Information";

import style from "./style.module.scss";
import Social from "pages/Social";

const Home = () => {
  return (
    <>
      <Information styles={style.main}>
        <h1>Welcome to North Manly Squash Club</h1>
        <img
          src={"assets/club-photo.jpeg"}
          alt={"Some of the Squash club members"}
        />
      </Information>

      <Information styles={style.main}>
        <Social isWhiteBG />
      </Information>
    </>
  );
};

export default Home;
