import API from "rest/api";
import { Field, Form } from "react-final-form";
import { FormQuestion } from "components/FormQuestion";
import classNames from "classnames";

const formUrl =
  "https://docs.google.com/forms/u/0/d/e/1FAIpQLSeooGaLYgxfrpKI5E_2jxZi_0a4M-Y7d4vhoPdsQizDLFfUIw/formResponse";

const ContactForm: React.FC = () => {
  const { mutate, isSuccess } = API.forms.useCreateForm();

  const onSubmit = ({ email }: { email: string }) => {
    console.log({ email });
    const formData: [string, string][] = [];
    formData.push(["entry.1562327063", email ?? "undefined"]);
    mutate({ url: formUrl, form: formData });
  };

  if (isSuccess) {
    return <p>We will be in touch!</p>;
  }
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, values, valid }) => (
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col space-y-2">
            <FormQuestion label="Let us know the best way to contact you (phone or email)">
              <Field name="email">
                {({ input }) => (
                  <input {...input} required className="border-2 p-4" />
                )}
              </Field>
            </FormQuestion>
            <button
              disabled={!values.email || !valid}
              type="submit"
              className={classNames(
                "border-2 rounded-lg self-center px-4 py-2 bg-slate-700 text-white w-full",
                { "bg-gray-500 cursor-not-allowed": !values.email }
              )}
            >
              Submit
            </button>
          </div>
        </form>
      )}
    />
  );
};

const Social: React.FC<{ isWhiteBG?: boolean }> = ({ isWhiteBG }) => {
  return (
    <div
      className={classNames({
        "space-y-4 p-4": !isWhiteBG,
      })}
    >
      <h1
        className={classNames({
          "text-white": !isWhiteBG,
        })}
      >
        The courts are closed for construction
      </h1>

      <div className="bg-white rounded-md p-4 space-y-4">
        <h2>Want us to let you know when the courts re-open?</h2>
        <ContactForm />

        <div className="space-y-2">
          <h4>Keep up to date with us on Socials!</h4>
          <div className="flex space-x-4">
            <a
              href="https://chat.whatsapp.com/FExpmUjOxV0L3PSoW3QJiU"
              className="w-8"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/assets/whatsapp.png" alt="whatsapp" />
            </a>
            <a
              href="https://www.facebook.com/groups/427687110671206"
              className="w-8"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/assets/facebook.png" alt="facebook" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Social;
